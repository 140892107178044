.operational-readiness-home .firstRow {
    height: 60px;
    background-color: white;
    font-weight: bold;
    font-size: 20px;
}

.operational-readiness-home .services-type {
    font-weight: bold;
    color: white;
    height: 100px;
    font-size: 35px;
}
.operational-readiness-home .secondRom {
    font-size: 20px;
}
.operational-readiness-home .services-type.first,
.operational-readiness-home .services-type.second,
.operational-readiness-home .services-type.third,
.operational-readiness-home .services-type.fourth {
    height: 800px;
    background-size: cover;
    background-repeat: no-repeat;
}
.operational-readiness-home .services-type.first {
    background-image: url('../../assets/images/Services/1.jpg');
}
.operational-readiness-home .services-type.second {
    background-image: url('../../assets/images/Services/2.jpg');
}
.operational-readiness-home .services-type.third {
    background-image: url('../../assets/images/Services/3.jpg');
}
.operational-readiness-home .services-type.fourth {
    background-image: url('../../assets/images/Services/4.jpg');
}
.operational-readiness-home .text {
    font-size: 18px;
}
.operational-readiness-home .textTitle {
    font-size: 20px;
}
