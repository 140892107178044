.main-header {
    background: #273e5e;
    padding: 2vh 2vw 0vh 2vw;
    height: calc(70px + 5vw);
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
}

.main-header .header-logo {
    flex: 1 1 30%;
    padding-bottom: 2vh;
}
.main-header .header-logo img {
    max-width: 100%;
    max-height: 100%;
}
.main-header .header-content {
    flex: 1 1 60%;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.navigation {
    display: flex;
    height: 100%;
    align-items: center;
}

.navigation .navigation-item {
    color: #fff;
    font-size: 2.2vmin;
    white-space: nowrap;
    margin-left: 2.5vw;
    text-decoration: none;
}

.navigation .navigation-item:hover {
    color: rgb(100, 99, 99);
}

.main-header .global-search {
    display: flex;
    align-items: flex-start;
    height: 80px;
}

.main-drawer .main-drawer-paper {
    display: flex;
    flex-direction: column;
    width: 320px;
    min-width: 200px;
    height: 100%;
    background-color: #1c2e46;
    padding: 2em 1em;
}

.main-drawer .MuiDrawer-paper {
    background-color: #1c2e46;
}

.main-drawer .main-drawer-paper .menu-item {
    text-decoration: none;
    margin-bottom: 8px;
    color: #d1d1d1;
    transition: color 0.2s;
    line-height: 40px;
    font-size: 1.1em;
    text-align: right;
}

.main-drawer .main-drawer-paper .menu-item:hover {
    color: white;
}

.main-drawer .main-drawer-paper .MuiDivider-root {
    background-color: gray;
}

.main-header .global-search .main-drawer-button {
    color: white;
    padding: 0;
    margin-left: 10px;
}

.search-form .hidden-input {
    visibility: hidden;
}

.search-form:hover .hidden-input {
    visibility: visible;
}

.main-header .global-search form {
    display: flex;
    align-items: flex-start;
}

.main-header .global-search .MuiFormControl-root {
    margin: 0 16px 0 0;
}

.main-header .global-search .MuiOutlinedInput-root {
    background-color: white;
}

.main-header .global-search .MuiOutlinedInput-input {
    padding: 8.5px 14px;
}

.main-header .global-search .MuiButton-root {
    background: #456a92;
    border-color: #597da5;
    border-radius: 50%;
    padding: 6px;
    min-width: auto;
    margin: 0;
}

.main-header .global-search .MuiButton-root .MuiButton-startIcon {
    margin: 0;
}

.main-header .navigation .MuiSvgIcon-root {
    vertical-align: middle;
}
.MuiMenu-paper {
    color: #1c2e46;
    background-color: transparent;
}
.MuiMenuItem-root {
    color: white;
    background-color: #273e5e;
}
.MenuItem {
    color: white;
    background-color: #273e5e;
}
.MuiPaper-root {
    color: white;
    background-color: #273e5e;
}
.MuiList-root {
    color: white;
    background-color: #273e5e;
    line-height: 5px;
    padding-top: 1px;
    padding-bottom: 1px;
}
.MuiList-root {
    background: #1c2e46;
    line-height: 5px;
    padding-top: 1px;
    padding-bottom: 1px;
}
.MuiList-padding {
    padding-top: 1px;
    padding-bottom: 1px;
}
