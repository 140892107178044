.all-videos {
  padding: 1vh 1vw;
  box-sizing: border-box;
}
.all-videos .grid-container {
  display: grid;
}

.all-videos .grid-container--fill {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 5px;
}

.all-videos .grid-element {
  background-color: rgb(236 236 236);
  width: auto;
  aspect-ratio: 16/9;
  overflow: hidden;
  position: relative;
}

.all-videos .grid-element .title {
  font-size: 125%;
  font-style: italic;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
}

.all-videos .grid-container .grid-element .content {
  display: inline-block;
  position: relative;
  right: -50%;
  transform: translateX(-50%);
  height: 100%;
}

.all-videos .grid-container .grid-element .content img {
  position: relative;
  max-height: 100%;
  max-width: 100%;
}

.all-videos section {
  padding: 5px 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  text-transform: uppercase;
  z-index: 999;
  width: 100%;
  color: white;
  background-image: linear-gradient(
    to bottom,
    rgb(0 0 0 / 1%),
    rgb(0 0 0 / 75%)
  );
}

/* .all-videos article .title {
  font-size: 124.99687499999999%;
  font-weight: 700;
}

.all-videos article {
  width: 25vw;
  height: 15vw;
  overflow: hidden;
  float: left;
  position: relative;
  border: 1px solid #ffffff;
  border-width: 0 1px 1px 0;
  margin: 0 0 calc(2% / 0.725) calc(2% / 0.725);
}
 */
