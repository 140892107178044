.all-blogs {
  padding: 1vh 1vw;
  box-sizing: border-box;
}
.all-blogs .grid-container {
  display: flex;
  flex-wrap: wrap;
}

.all-blogs .grid-element {
  background-color: rgb(236 236 236);
  width: auto;
  overflow: hidden;
  position: relative;
  max-height: 375px;
  max-width: 375px;
}

.all-blogs .grid-element .title {
  font-size: 125%;
  font-style: italic;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  box-sizing: border-box;
}

.all-blogs .grid-container .grid-element .content {
  display: inline-block;
  position: relative;
  right: -50%;
  transform: translateX(-50%);
  max-height: 375px;
}

.all-blogs .grid-container .grid-element .content img {
  position: relative;
  max-height: 375px;
}

.all-blogs .grid-container a {
  margin: 0 5px 5px 0;
}

.all-blogs section {
  padding: 5px 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  text-transform: uppercase;
  z-index: 999;
  width: 100%;
  color: white;
  background-image: linear-gradient(to bottom, rgb(0 0 0 / 1%), rgb(0 0 0 / 75%));
}
