.profile {
  padding: 1vh 1vw;
}

.profile .service-flex .profile-side {
  flex: 1 1 20%;
  min-width: 150px;
}

.profile .service-flex .profile-content {
  flex: 1 1 65%;
  min-width: 250px;
  padding: 0 2vw 0 3vw;
}

.profile img {
  width: 100%;
}

.profile .profile-content .title {
  line-height: 30px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.profile .profile-content .content {
  line-height: 35px;
  text-align: justify;
}
