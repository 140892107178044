.people {
    padding: 2vh 2vw;
    box-sizing: border-box;
}

.people .people-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    column-gap: 100px;
    row-gap: 100px;
}

.people .people-grid .people-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.people .people-grid .people-item a,
.people .people-grid .people-item img {
    width: 100%;
    max-width: 350px;
}

.people .people-grid .people-item a {
    text-decoration-color: black;
}

.people-item .title {
    text-align: center;
    line-height: 30px;
    text-decoration: none;
    color: black;
}
.people-item .position {
    text-align: center;
    line-height: 30px;
    text-decoration: none;
    color: black;
}
