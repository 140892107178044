.podcast .main-content img {
  width: 100%;
}

.podcast .main-content {
  width: 75%;
  float: left;
  padding: 4vh 5vw;
}

.podcast .right-content {
  width: 25%;
  float: right;
  padding-left: 15px;
}

.podcast .main-content .title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
}
